import React, { Component } from 'react'
// import { Link } from 'react-router-dom';

// function refreshPage(){
//   setTimeout(()=>{
//     window.location.reload(false);
//   }, 500);
 
// }

export default class Footer extends Component {
  render() {
    return (
      // <div Align="center" style={{backgroundColor: 'aqua'}} >
      // <div  style={{backgroundColor: '#ffc72c', paddingTop:'1em'}} >
       <div  style={{backgroundColor: '#faab00', paddingTop:'1em'}} >
    
        {/* <ColoredLine color="gold" />            */}
        {/* <br/>       */}
        <div className="footers"  style={{WebkitTextFillColor:'#2FA450', fontFamily: 'Georgia'}}>Dictionary Plus+ &copy;2022
         {/* <br/> <small>v2.1</small> */}
        </div>
      </div>

      
    )
  }
}
